import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import login_module from "./modules/login";
import baseArrays_module from "./modules/baseArrays";

export default new Vuex.Store({
    // state: {},
    // mutations: {},
    // actions: {},
    // getters: {},
    modules: {
        module_login: login_module,
        baseArrays: baseArrays_module,
    }
})
