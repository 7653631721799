// Als je ook apart de actions, state e.a. in aparte files wil zetten
// import actions from './actions';
// import getters from './getters';
// import mutations from './mutations';
// import state from './state';
import axios from "axios";

export default {
    state: {
        info_login: null,
        headerBearer: null,
        pageAuth: false,
        menuItems: null,
        menuItemsBase: [],
        menuItemsAdm: [],
    },
    mutations: {
        save_login(state, data) {
            state.info_login = data;
            sessionStorage.setItem("info_login", JSON.stringify(state.info_login));
            //console.log("info_login van sessionStorage = ", JSON.parse(sessionStorage.getItem("info_login")));
            state.info_login.headerBearer = 'Bearer ' + state.info_login.id + ',' + state.info_login.token;
            //state.info_login.headerBearer = `Bearer ${state.info_login.id + ',' + state.info_login.token}`; // kan ook!
            axios.defaults.headers.common['Authorization'] = state.info_login.headerBearer;
            //console.log("axios.defaults.headers.common['Authorization'] = ", axios.defaults.headers.common['Authorization']);
            
            // beheer weergave en toegang tot menuitems afhankelijk van rol
            switch (state.info_login.rol) {
                case 'adm': state.menuItems = state.menuItemsAdm; break;
                case 'doc': state.menuItems = state.menuItemsDoc; break;
                default:    state.menuItems = state.menuItemsBase;
            }
            //sessionStorage.setItem("menuItems", JSON.stringify(state.menuItems));
            //console.log("from store, module_login save_login. state.menuItems=", state.menuItems)
        },

        logout(state) {
            sessionStorage.removeItem("info_login");
            state.info_login = null;
            //console.log("in logout() in store: sessionStorage en  state.info_login zijn gewist")
            state.menuItems = state.menuItemsBase;
            //console.log("from store, module_login logout. state.menuItems=", state.menuItems)
        },

        authPageCheck(state, r) {
            //console.log("Vanuit Inlog.vue created: local info_login uit sessionStorage = ", info_login);
            // op de een of andere manier is state.info_login hier leeg..?? Dus haal ik info_login op uit de sessionStorage
            let info_login = JSON.parse(sessionStorage.getItem("info_login"))
            if (info_login) {
                //console.log("in authPageCheck() in store: state.info_login is er niet meer..:", state.info_login)
                //console.log("from store, authPageCheck. r = " + r, "info_login = ", info_login)
                if (info_login && (info_login.rol == r || info_login.rol == 'adm') ) {
                    state.pageAuth = true
                }
                /*
                TODO:
                haal 
                    $aAuthLevelAdm    = ['adm'];
                    $aAuthLevelDoc    = ['adm', 'doc'];
                    $aAuthLevelStd    = ['adm',        'std'];
                    $aAuthLevelStdDoc = ['adm', 'doc', 'std'];
                    $aAuthLevelAll    = ['adm', 'doc', 'std', 'all'];
                van api en pas deze bij bovenstaande toe ipv admin altijd alles te geven.
                Nu kun je maar 2 rollen toepssen (all is geen rol): doc waarbij adm alles kan, 
                maar als je std hebt waarbij doc alles kan, heb je aAuthLevels nodig.
                */
            }	
        },

        initializeMenu(state) {
            state.menuItemsBase = [
                { title: "Home", icon: "mdi-home", to: "/", sub_items: null },
                { title: "project s2.2", icon: "mdi-script-text-play-outline", to: "/edu/pr22", sub_items: null },
                { title: "colorgame", icon: "mdi-script-text-play-outline", to: "/edu/cg", sub_items: null },
                // { title: "csp1", icon: "mdi-script-text-play-outline", to: "/edu/csp1", sub_items: null },
            ]

            state.docItems = [
                {
                    title: "doc", icon: "mdi-human-male-board", to: null, sub_items: [
                        { title: "Students", icon: "mdi-account-multiple", to: "/std", sub_items: null },
                        { title: "Students_xls", icon: "mdi-account-multiple", to: "/stdXls", sub_items: null },
                        { title: "Lessen", icon: "mdi-text-box-search-outline", to: "/lessen", sub_items: null },
                        { title: "Edit edu", icon: "mdi-view-dashboard-edit-outline", to: "/edu/edit", sub_items: null },
                        { title: "Beoordeling", icon: "mdi-numeric-7-box-multiple-outline", to: "/edu/eval", sub_items: null },
                    ]
                },
            ]

            state.admItems = [
                {
                    title: "Tests", icon: "mdi-test-tube", to: null, sub_items: [
                        { title: "About", icon: "mdi-information", to: "/about", sub_items: null },
                        { title: "Users", icon: "mdi-account-multiple-check", to: "/users", sub_items: null },
                        { title: "Dragdrop", icon: "mdi-drag-variant", to: "/dragdrop", sub_items: null },
                        { title: "Dragdrop2", icon: "mdi-drag-variant", to: "/dragdrop2", sub_items: null },
                        { title: "Dragdrop3", icon: "mdi-drag-variant", to: "/dd3", sub_items: null },
                        
                        { title: "Bier", icon: "mdi-glass-mug-variant", to: "/bier", sub_items: null },
                        { title: "Bier base", icon: "mdi-glass-mug-variant", to: "/beer_base", sub_items: null },
                    ]
                },
                {
                    title: "admin", icon: "mdi-shield-crown-outline", to: null, sub_items: [
                        { title: "settings", icon: "mdi-cog-box", to: "/ShowSet", sub_items: null },
                        { title: "Admin app", icon: "mdi-shield-crown-outline", to: "/Adm", sub_items: null },
                        { title: "edu/all", icon: "mdi-information", to: "/edu/all", sub_items: null },
                    ]
                },
            ]

            state.menuItemsAdm = [...state.menuItemsBase, ...state.docItems, ...state.admItems];
            state.menuItemsDoc = [...state.menuItemsBase, ...state.docItems];
            state.menuItems = state.menuItemsBase; // standaard setting, laten staan!!
        }
    
    },
    actions: {
    },
    getters: {
        // infoLogin: state => {
        //     return state.info_login;
        // }
    }
}