<template>

    <div v-if="$store.state.module_login.info_login" class="d-flex flex-wrap">
        <table class="tblA standard pa-2 mb-3" >
            <tr>
                <th colspan="2" style="font-weight:600">Inloginfo</th>
            </tr>
            <tr v-for="(map, index) in $store.state.module_login.info_login" v-bind:key="index">
                <th>{{index}}:</th>
                <td>{{map}}</td>
            </tr> 
        </table> 
        <table class="tblA standard pa-2 mb-3">
            <tr>
                <th colspan="2" style="font-weight:600">mappen</th>
            </tr>
            <tr v-for="(map, index) in $store.state.baseArrays.appPaths" v-bind:key="index">
                <th>{{index}}:</th>
                <td>{{map}}</td>
            </tr> 
        </table> 
        <table class="tblA standard pa-2 mb-3">
            <tr>
                <th colspan="2" style="font-weight:600">API's op servers</th>
            </tr>
            <tr v-for="(map, index) in $store.state.baseArrays.appPaths.apiServer" v-bind:key="index">
                <th>{{index}}:</th>
                <td>{{map}}</td>
            </tr> 
        </table> 
    </div>
    <div v-else>Niet ingelogd, info niet zichtbaar</div>
</template>

<script>

export default {
	//name: "HomeBase",	
};
</script>

<style scoped>
    th {
        background-color:rgba(255,255,255,0.6);
        font-weight:400;
        font-style:italic;
        text-align: left;
    }
    td {
        background-color:rgba(255,255,255,0.8);
    }

    div {
        max-height:400px;
        overflow:auto;
    }
</style>
