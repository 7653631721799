<template>
	<div class="ma-4">
        <div class="homeHeader" >
            
            <img src="@/assets/logos/cs_eduplan6.png" style="height:3em">
            
            <div style="font-size:3em; font-weight:700; color:white">home eduplan</div>
            <img src="@/assets/logos/cs_wit.png" style="height:3em">
        </div>

        <div class="homeHeader sub">
            <v-card>
                <i>Content website:</i><br> 
                lesplanningen Softwaredeveloper vakken binnen het ICT-Lyceum.<br/>
            </v-card>

             <div>
                <img src="@/assets/logos/deltion.gif" style="max-height:80px">
            </div>
            
            <v-card>
                <i>Applicatie:</i><br>
                Vue2 / API (PHP) &nbsp; -  &nbsp; &copy;stegeman
            </v-card>
        </div>

        <div v-if="$store.state.module_login.info_login" class="homeHeader white--text" style="justify-content: center;">
            <h3 class="float-left mr-3">ingelogd:</h3>
            <v-card style="background-color:rgba(255,255,255,0.3);">
                <table class="ml-2 csSet white--text" >
                    <tr>
                        <td>Naam:</td>
                        <td>{{$store.state.module_login.info_login.naam}}</td>
                    </tr>
                    <tr>
                        <td>Inlognaam:</td>
                        <td>{{$store.state.module_login.info_login.inaam}}</td>
                    </tr>
                    <tr>
                        <td>Rol:</td>
                        <td>{{$store.state.module_login.info_login.rol}}</td>
                    </tr>
                </table> 
            </v-card>
        </div>

        <div class="homeHeader" v-if="$store.state.module_login.info_login">
             <div v-if="$store.state.module_login.info_login.rol == 'adm'">
                <h2 style="color:white">Admin-view only:</h2>
                <show-set></show-set>
             </div>
        </div>    
	</div>
</template>

<script>
import ShowSet from "@/components/drawers_dialogs_bars/ShowSet";

export default {
	//name: "HomeBase",	

    components: {
        ShowSet,
	},
};
</script>

<style scoped>
    .homeHeader {
        display:flex; 
        align-items: center; 
        justify-content:space-evenly; 
        margin:10px auto; 
        padding:20px;
        width:70%; 
        background-color:rgba(0,0,0,0.6); 
        border-radius:4px;
    }

    .homeHeader.sub {
        background-color:rgba(255,255,255,0.6);
    }

    .homeHeader .v-card {
        width:300px; 
        text-align:center; 
        background-color:rgba(0,0,0,0.06);
    }

    .csSet { text-align:left; } 
    .csSet td { padding:0 4px; } 
    .csSet:first-of-type td { font-style:italic; } 
</style>
