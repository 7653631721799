import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueExcelEditor from 'vue-excel-editor'
// import axios from "axios";
// axios.defaults.baseURL = 'http://localhost/stagion/bpv/api_bpv_v.php' // aleen interssant als endpoints met / geregeled zijn

import './assets/styles/main.css'

Vue.use(VueExcelEditor)
Vue.config.productionTip = false

Vue.filter('formatDate', function (arrFormat) {
    let d = arrFormat[0];
    let output = d;
    let format = arrFormat[1];

    function addZero(i) {
        if (i < 10) { i = "0" + i }
        return i;
    }
    
    if (d instanceof Date) {
        
        let aMonShort = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
        let aMonLong = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
        let aDayLong = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
        let aDayShort = ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'];
        //console.log("d =" + d, "format=" + format);
        let f = {
            'lDate': aDayLong[d.getDay()] + ' ' + d.getDate() + ' ' + aMonLong[d.getMonth()] + ' ' + d.getFullYear(),
            'lDT': aDayLong[d.getDay()] + ' ' + d.getDate() + ' ' + aMonLong[d.getMonth()] + ' ' + d.getFullYear(),
            'sD': aDayShort[d.getDay()] + ' ' + d.getDate() + ' ' + aMonLong[d.getMonth()] + ' ' + d.getFullYear(),
            'sDT': aDayLong[d.getDay()] + ' ' + d.getDate() + ' ' + aMonLong[d.getMonth()] + ' ' + d.getFullYear(),
            'xsDT': d.getDate() + ' ' + aMonShort[d.getMonth()] + ' ' + addZero(d.getHours()) + ':' + addZero(d.getMinutes()) + ':' + addZero(d.getSeconds()),
        }
        
        output = f[format];
    } 
    
    return output;
})

Vue.filter('testing', function ([val1, val2]) {
    if (!val1) return ''
    val1 = val1.toString()
    val2 = val2.toString()
    return val1 + val2
})

Vue.filter('capFirstChar', function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
})

new Vue({
  router,
  store,
  vuetify,
  
  render: h => h(App)
}).$mount('#app')
