import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'; // nodig om vuetify colors hieronder te gebruiken

Vue.use(Vuetify, {
    /* onderstaande maakt het mogelijk om theme colors ook in css te gebruiken: color:var(--v-primary-base); 
      maar werkt niet..? 
      https://stackoverflow.com/questions/48280990/using-custom-theming-in-vuetify-and-pass-color-variables-to-components
    */
    options: {
        customProperties: true
    }
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.blueGrey.darken2,  //deepPurple, teal, cyan, indigo, blueGrey
                secondary: colors.red.darken1,
                accent: colors.indigo.accent1,  //accent: colors.shades.white,
                error: colors.red.accent3,
            },
            dark: {
                primary: colors.blue.lighten3,
            },
        },
    },
});
