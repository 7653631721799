<template>
	<v-app >

        <Menu/>

		<v-main class="vMain">
            <!-- <user-info v-if="$store.state.module_login.info_login"/> -->
			<router-view 
                class = "normalMargin" 
            />
            <!-- v-bind:class="{ leftMargin: $store.state.module_login.info_login }"  -->
		</v-main>

		<snack-bar></snack-bar>
	</v-app>
</template>

<script>
import Menu from "@/components/layout/Menu.vue";
import SnackBar from "@/components/drawers_dialogs_bars/SnackBar";
//import UserInfo from "@/components/drawers_dialogs_bars/UserInfo";

export default {
	name: "App",
	components: {
        Menu,
		SnackBar,
        //UserInfo,
	},

	data: () => ({
		dialog: true,       
	}),

    created: function () {
        this.$store.commit("initializeMenu");
        //console.log("Vanuit App.vue created: initializeMenu aangeroepen  state.menuItems= ", this.$store.state.module_login.menuItems );

		// haal (bij refressh van de pagina) info_login op uit sessionStorage indien aanwezig
        let info_login = JSON.parse(sessionStorage.getItem("info_login"))
        if(info_login) {
            this.$store.commit("save_login", info_login);
        }	
        //this.$store.commit("save_login", info_login);
        //console.log("Vanuit App.vue created: local info_login uit sessionStorage = ", info_login);

        this.$store.commit("getRootPaths", null);
        //console.log("from app.vue: state.appPaths =", this.$store.state.baseArrays.appPaths);
        // bijv.: this.$store.state.baseArrays.appPaths.baseUrlAction        
	},
};
</script>

<style>
    @import './assets/styles/main.css';
    @import './assets/styles/edu.css';
</style>
