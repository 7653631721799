import axios from "axios";
import store from '../store'
//let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
//console.log("from user.js. store.state.baseArrays.appPaths = ", store.state.baseArrays.appPaths);
//import baseUrlAction from './api_ini.js'

export const getUsers = async () => {
    try {
        let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
        const response = await axios.get(baseUrlAction + 'getUsers');
        console.log("response (met config en header info) FROM user.js = ", response);
        return response.data;
        // hier moet hij complete response.data teruggeven!!, want je wilt ook de succes en de sMessage in de view afhandelen
        // hier kent hij this.$root bijvoorbeeld, dus geen emit.snackBar, want deze file zit niet in de scope
    } catch (err) {
        console.error(err);
    }
};

export const getUser = async (inaam, pw) => {
    let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
    const url = baseUrlAction + 'getUser';
    //console.log("inaam =" + inaam, "pw =" + pw);
    //console.log("url = " + url);
    const payload = { inaam: inaam, pw: pw }; // volgorde velden maakt niet meer uit door aanpassing in API 
    const response = await axios.post(url, payload);
    console.log("response request from user.js", response);
    return response
}
