// Als je ook apart de actions, state e.a. in aparte files wil zetten
// import actions from './actions';
// import getters from './getters';
// import mutations from './mutations';
// import state from './state';

//import axios from "axios";

export default {
    state: {
        appPaths: {
            appHref: null,
            appRootPath: null,
            appHostname: null,
            appPathname: null,
            appProtocol: null,
            baseUrlAction: null,
        }
    },
    mutations: {
        getRootPaths (state) {
            let wloc = window.location;
            state.appPaths.appHref        = wloc.href;
            state.appPaths.appHostname    = wloc.hostname;
            state.appPaths.appPathname    = wloc.pathname;
            state.appPaths.appProtocol    = wloc.protocol;
            state.appPaths.appRootPath    = wloc.protocol + "//" + wloc.hostname + "/";
            state.appPaths.pathAPI        = "/api/index.php?action=";
            state.appPaths.rootPathClient = "http://localhost/github/eduplan/public";
            
            state.appPaths.apiServer = {
                'bpv': 'https://bpv.stagion.nl/model/api.php',
                'exbeo': 'https://exbeo.stagion.nl/app/api.php',
                'eduplan': 'https://edu.stegion.nl/api/index.php',
            }
            // bepaal of API extern of localhost staat
            state.appPaths.baseUrlAction = state.appPaths.appRootPath + state.appPaths.pathAPI;

            if (state.appPaths.appHref.includes("808") || state.appPaths.appHref.includes("localhost")) {
                //console.log("URL bevat '808' of 'localhost'. URL is: " + state.appPaths.appHref);
                state.appPaths.baseUrlAction = state.appPaths.rootPathClient + state.appPaths.pathAPI;
                //state.appPaths.baseUrlAction = "http://localhost/api_eduplan/index.php?action=";
            }
            //console.log("from vuex store: state.appPaths =", state.appPaths);
        }
    },
    actions: {
    },
    getters: {
        // 
    }
}