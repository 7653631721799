import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// import Home: normaal inladen. Anders: met import in route zelf voor route level code-splitting for lazy-loading
import Home from '../views/Home.vue' 

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: false, r:'all' }
    },
    {
        path: '/login',
        name: 'HomeLogin',
        component: Home,
        meta: { requiresAuth: false, r: 'all' }
    },
    {
        path: '/unauth',
        name: 'unauth',
        component: () => import('../views/Unauth.vue'),
        meta: { requiresAuth: false, r: 'all' }
    },

/* --------start --------- pagina's onder views/tests ----------------------------*/
    {
        path: '/Dragdrop',
        name: 'Dragdrop',
        component: () => import('../views/tests/Dragdrop.vue'),
        meta: { requiresAuth: true, r:'adm' }
    },
    {
        path: '/Dragdrop2',
        name: 'Dragdrop2',
        component: () => import('../views/tests/Dragdrop2.vue'),
        meta: { requiresAuth: true, r:'adm' }
    },
    {
        path: '/dd3',
        name: 'dd3',
        component: () => import('../views/tests/dd3.vue'),
        meta: { requiresAuth: true, r: 'adm' }
    },
    {
        path: '/dd4',
        name: 'dd4',
        component: () => import('../views/tests/dd4.vue'),
        meta: { requiresAuth: true, r: 'adm' }
    },
    {
        path: '/About',
        name: 'About',
        component: () => import('../views/tests/About.vue'),
        meta: { requiresAuth: true, r:'adm' }
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('../views/tests/Users.vue'),
        meta: { requiresAuth: true, r:'adm' }
    },
    {
        path: '/beer_base',
        name: 'Beer_base',
        component: () => import('../views/beer/Beer.vue'),
        meta: { requiresAuth: true, r:'adm' }
    },
    {
        path: '/bier',
        name: 'Bier',
        component: () => import('../views/beer/Bier.vue'),
        meta: { requiresAuth: true, r:'adm' }
    },
    {
        path: '/Adm',
        name: 'Adm',
        component: () => import('../views/tests/Adm.vue'),
        meta: { requiresAuth: true, r:'adm'  }
    },
    {
        path: '/ShowSet',
        name: 'ShowSet',
        component: () => import('../components/drawers_dialogs_bars/ShowSet.vue'),
        meta: { requiresAuth: true, r: 'adm' }
    },
    {
        path: '/std',
        name: 'std',
        component: () => import('../views/studenten/std.vue'),
        meta: { requiresAuth: true, r: 'doc' }
    },
    {
        path: '/stdXls',
        name: 'stdXls',
        component: () => import('../views/studenten/stdXls.vue'),
        meta: { requiresAuth: true, r: 'doc' }
    },
/* --------stop ---------- pagina's onder views/tests ----------------------------*/

/* --------start ---------- pagina's onder views/tecsp1/colorgame ----------------------------*/
    // {
    //     path: '/csp1/cg10',
    //     name: 'colorgame 1',
    //     component: () => import('../views/csp1/colorgame/cg10.vue'),
    //     meta: { requiresAuth: false, r: 'all' }
    // },
    // {
    //     path: '/csp1/cg20',
    //     name: 'colorgame 2',
    //     component: () => import('../views/csp1/colorgame/cg20.vue'),
    //     meta: { requiresAuth: false, r: 'all' }
    // },
    {
        path: '/edu/pr22',
        name: 'edu/pr22',
        component: () => import('../views/edu/edu.vue'),
        meta: { requiresAuth: false, r: 'all' }
    },
    {
        path: '/edu/csp1',
        name: 'edu/csp1',
        component: () => import('../views/edu/edu.vue'),
        meta: { requiresAuth: false, r: 'all' }
    },
    {
        path: '/edu/cg',
        name: 'edu/cg',
        component: () => import('../views/edu/edu.vue'),
        meta: { requiresAuth: false, r: 'all' }
    },
    {
        path: '/edu/all',
        name: 'edu/all',
        component: () => import('../views/edu/edu_new.vue'),
        meta: { requiresAuth: false, r: 'all' }
    },
    {
        path: '/edu/edit',
        name: 'edu/edit',
        component: () => import('../views/edu/edu_edit.vue'),
        meta: { requiresAuth: true, r: 'doc' }
    }, 
    {
        path: '/edu/eval',
        name: 'edu/eval',
        component: () => import('../views/edu/eval.vue'),
        meta: { requiresAuth: true, r: 'doc' }
    },
/* --------stop ----------- pagina's onder views/tecsp1/colorgame ----------------------------*/
]

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL+"/edu",
    routes
})

router.beforeEach((to, from, next) => {
    //console.log("in router.beforeEach")
    store.commit("authPageCheck", to.meta.r);
    
    if (to.meta.requiresAuth) {
        //console.log("from router. store.state.module_login.pageAuth=", store.state.module_login.pageAuth)
        if (!store.state.module_login.pageAuth) next('/Unauth')
        else next()
    } else next()
})

export default router
